import * as Sentry from '@sentry/browser';

const rootDataSet = document.documentElement.dataset;

Sentry.init({
    dsn: rootDataSet.sentryJsDsn,
    enabled: rootDataSet.sentryJsEnabled !== undefined,
    environment: rootDataSet.sentryJsEnvironment,
    release: rootDataSet.sentryJsRelease
});

export default Sentry;
